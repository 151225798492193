<script setup lang="ts">
import Alert from '@libero/ui-framework/Alert/Alert.vue';
import FormLayout from '@libero/ui-framework/FormLayout/FormLayout.vue';
import HeightMotion from '@libero/ui-framework/HeightMotion/HeightMotion.vue';

interface Props {
  error?: string;
  onSubmit: () => void;
}

const props = defineProps<Props>();

const handleSubmit = (event: Event) => {
  event.preventDefault();
  props.onSubmit();
};
</script>

<template>
  <form class="form" :onSubmit="handleSubmit">
    <HeightMotion>
      <FormLayout v-if="error">
        <Alert type="error">
          {{ error }}
        </Alert>
      </FormLayout>
    </HeightMotion>

    <slot />
  </form>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Form/Form.scss';
</style>
