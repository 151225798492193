<script setup lang="ts">
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import { computed } from 'vue';
import type { RouterLocation } from 'vue-router';

interface Props {
  to?: RouterLocation;
  href?: string;
  title?: string;
  bold?: boolean;
  underline?: boolean;
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary' | 'error';
  isDisabled?: boolean;
  onClick?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  href: undefined,
  to: undefined,
  title: undefined,
  size: 'md',
  color: 'primary',
  onClick: undefined,
});

const classes = computed(() => {
  return {
    ['link']: true,
    ['size-' + props.size]: !!props.size,
    ['has-underline']: !!props.underline,
    ['is-bold']: !!props.bold,
    ['is-disabled']: !!props.isDisabled,
    ['color-' + props.color]: true,
  };
});
</script>

<template>
  <Clickable
    :to="to"
    :href="href"
    :class="classes"
    :title="title"
    :isDisabled="isDisabled"
    :onClick="onClick"
  >
    <slot />
  </Clickable>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Link/Link.scss';
</style>
