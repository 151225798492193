import { ApiError } from '@libero/types/Error';
import { message } from 'ant-design-vue';
import ky, { type AfterResponseHook, Options } from 'ky';

import { trackEvent } from './analytics';
import { generateUrl } from './base-url';
import { createJsonApiClient } from './json-api-client';

export const afterResponseHooks: AfterResponseHook[] = [];

export type ApiOptions = Options;

const api = ky.create({
  credentials: 'include',
  prefixUrl: generateUrl(),
  retry: 0,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', localStorage.getItem('bearer-token') || '');
      },
    ],
    beforeError: [
      async (error) => {
        const response = await error.response.json();

        (error as ApiError).response.data = response;

        if (error.response.status === 403) {
          message.error(response.message);
          history.back();

          trackEvent({
            category: 'API',
            action: 'response_error',
            label: response.message,
          });
        }

        return error;
      },
    ],
    afterResponse: [
      (...args) => {
        afterResponseHooks.forEach((hook) => hook(...args));
      },
    ],
  },
});

const jsonApi = createJsonApiClient(api);

export { api, jsonApi };
